<!--  -->
<template>
  <div class="box nameone">
    <div class="header">
      <div class="header_left">
        <span class="header_left_title">招聘趋势</span>
        <span
          style="font-size:12px;color:#5c5e6b;margin:6px;"
        >{{dateVal[0]&&dateVal[0].slice(0,10)}} 至 {{dateVal[1]&&dateVal[1].slice(0,10)}}</span>
        <span>
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <i class="el-icon-setting"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">详情</el-dropdown-item>
              <el-dropdown-item command="1">下载</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
    </div>
    <charts-time @timeChange="timeChange" ref="chartsTime"></charts-time>
    <div id="myChart"></div>
    <el-dialog :visible.sync="dialogFormVisible">
      <div
        slot="title"
        style="display: flex;justify-content: center;align-items: center;color:#484848;"
      >招聘趋势统计图详细数据</div>
      <div class="fr"  style="margin: -10px 0 10px;">
        <el-button size="small" type="primary" @click="exportOut" icon="el-icon-download">导出</el-button>
      </div>
      <div style="overflow:auto;height : 500px;width: 100%;">
        <el-table :data="dataList" tooltip-effect="dark" v-loading="loading" style="width: 100%" max-height="450">
          <el-table-column prop="date" label="日期" align="center"></el-table-column>
          <el-table-column prop="newCount" label="接收简历" align="center"></el-table-column>
          <!-- <el-table-column prop="jobName"
                          label="推荐通过"
          align="center"></el-table-column>-->
          <el-table-column prop="interviewedCount" label="已面试" align="center"></el-table-column>
          <el-table-column prop="offerCount" label="offer" align="center"></el-table-column>
          <el-table-column prop="hasHiredCount" label="已入职" align="center"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chartsTime from './chartsTime.vue';
// probability
import { statisticsHiringFunnel} from '@/api/recruitment_allocation.js';
export default {
  components: {
    chartsTime
  },
  data() {
    return {
      dateVal: [],
      dialogFormVisible: false,
      loading: false,
      dataList: [],
      yAxis: {
        name: '',
        nameTextStyle: {
          color: '#484848'
        },
        axisLabel: { color: '#484848' },
        splitArea: { show: false }, // 去除网格区域
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: '#D8D8D8',
            width: 1
          }
        }
      }
    };
  },
  mounted() {
    this.dateVal = this.$refs.chartsTime.dateVal;
    this.getReport();
  },
  methods: {
    handleCommand(command) {
      if (command == '0') {
        this.dialogFormVisible = true;
      }
      if (command == '1') {
        this.exportOut();
      }
    },
    exportOut() {
      const outParams = {
        startTime: this.dateVal[0],
        endTime: this.dateVal[1]
      };

      const outStr = encodeURIComponent(JSON.stringify(outParams));

      const url =
        `/v1/recruit/statistics/hiring/probability/export?params=${outStr}`;

      window.open(document.location.origin + url);
    },
    timeChange(data) {
      this.dateVal = data;
      this.getReport();
    },
    getReport() {
      const myChart = this.$echarts.init(document.getElementById('myChart'));
        const newCount = [];
        const interviewedCount = [];
        const offerCount = [];
        const hasHiredCount = [];
        const XData = [];

      statisticsHiringFunnel({ startTime: this.dateVal[0], endTime: this.dateVal[1] })
        .then((res) => {
          res = res.reverse();
          this.dataList = res;
          res.forEach((item) => {
            XData.push(item.date.slice(5));
            newCount.push(item.newCount);
            interviewedCount.push(item.interviewedCount);
            offerCount.push(item.offerCount);
            hasHiredCount.push(item.hasHiredCount);
          });
          //   this.tableList = res.list
          //   this.table.total = res.totalNum
          this.loading = false;
        })
        .then(() => {
          myChart.clear();
          myChart.resize();
          myChart.setOption({
            tooltip: {
              trigger: 'axis',
              textStyle: {
                align: 'left'
              }
            },
            color: ['#FF7371', '#ffc457', '#37CDF6', '#5F4CF6'],
            legend: {
              x: 'center',
              y: 'bottom',
              data: ['接收简历', '已面试', 'offer', '已入职'],
              selected: {
                '接收简历': newCount.length > 0,
                '已面试': interviewedCount.length > 0,
                'offer': offerCount.length > 0,
                '已入职': hasHiredCount.length > 0
              }
            },
            grid: {
              //   left: '3%',
              //   right: '4%',
              //   bottom: '3%',
              //   containLabel: true
            },
            xAxis: {
              name: '日期',
              nameTextStyle: {
                color: '#484848'
              },
              type: 'category',
              axisLabel: { color: '#484848' },
              axisLine: {
                lineStyle: {
                  color: '#D8D8D8', // 颜色
                  width: 1 // 粗细
                }
              },
              boundaryGap: false,
              data: XData
            },
            yAxis: this.yAxis,
            series: [
              {
                name: '接收简历',
                type: 'line',
                data: newCount
              },
              {
                name: '已面试',
                type: 'line',
                data: interviewedCount
              },
              {
                name: 'offer',
                type: 'line',
                data: offerCount
              },
              {
                name: '已入职',
                type: 'line',
                data: hasHiredCount
              }
            ]
          });
        });
    }
  }
};
</script>
<style lang='scss' scoped>
.box {
  padding-left: 12px;
  padding-bottom: 12px;
  // border: 1px solid #e5e5e5;
}
.nameone .fr {
  margin: -10px 0 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}
#myChart {
  height: 400px;
  width: 100%;
}
.header_left {
  .header_left_title{
    font-size:16px;
    color:#484848;
    font-weight: bold;
  }
  &:before {
    display: inline-block;
    width: 8px;
    height: 22px;
    background: #498df0;
    content: " ";
    border-radius: 5px;
    transform: translateY(-1px);
    vertical-align: middle;
    margin-right: 8px;
  }
}
</style>
