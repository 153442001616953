<!--  -->
<template>
  <div class="namethree">
    <div class="box">
      <div class="header">
        <div class="header_left">
          <span class="header_left_title">接收简历趋势</span>
          <span style="font-size:12px;color:#5c5e6b;margin:6px;">{{dateVal[0]&&dateVal[0].slice(0,10)}} 至 {{dateVal[1]&&dateVal[1].slice(0,10)}}</span>
          <span>
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <i class="el-icon-setting"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="receiveDetail">详情</el-dropdown-item>
                <el-dropdown-item command="receiveExport">下载</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
      </div>
      <charts-time @timeChange="timeChange" ref="chartsTime"></charts-time>
      <div id="myChart_line" v-show="seriesData.length>0"></div>
      <section v-show="seriesData.length==0" class="nodata">
        <div class="img_box">
          <img class="img_box_img"
              src="@/assets/developping_images/developping.png">
        </div>
        <!-- 文字说明 -->
        <div class="desc_box">
          <span>暂无数据</span>
        </div>
      </section>
    </div>
    <div class="round_box">
      <div class="round_item" style="margin: 24px;margin-left:0;">
        <div class="header">
          <div class="header_left">
            <span class="header_left_title">渠道占比</span>
            <span
              style="font-size:12px;color:#5c5e6b;margin:6px;"
            >{{dateVal[0]&&dateVal[0].slice(0,10)}} 至 {{dateVal[1]&&dateVal[1].slice(0,10)}}</span>
            <span>
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <i class="el-icon-setting"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="sourceDetail">详情</el-dropdown-item>
                  <el-dropdown-item command="sourceExport">下载</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
        <div id="round_one" v-show="round_oneData.length>0"></div>
        <section v-show="round_oneData.length==0" class="nodata1">
          <div class="img_box">
            <img class="img_box_img"
                src="@/assets/developping_images/developping.png">
          </div>
          <!-- 文字说明 -->
          <div class="desc_box">
            <span>暂无数据</span>
          </div>
        </section>
        <el-dialog :visible.sync="sourceDialog">
          <div
            slot="title"
            style="display: flex;justify-content: center;align-items: center;color:#484848;"
          >渠道占比图详细数据</div>
          <div class="fr">
            <el-button size="small" icon="el-icon-download" type="primary"
                      @click="exportOut('sourceExport')">导出
            </el-button>
          </div>
          <el-table
            :data="sourceList"
            tooltip-effect="dark"
            v-loading="loading"
            style="width: 100%"
          >
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column prop="sourcesName" label="渠道" align="center"></el-table-column>
            <el-table-column prop="number" label="数量" align="center"></el-table-column>
            <el-table-column prop="rate" label="占比" align="center"></el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <div class="round_item">
        <div class="header">
          <div class="header_left">
            <span class="header_left_title">岗位占比</span>
            <span
              style="font-size:12px;color:#5c5e6b;margin:6px;"
            >{{dateVal[0]&&dateVal[0].slice(0,10)}} 至 {{dateVal[1]&&dateVal[1].slice(0,10)}}</span>
            <span>
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <i class="el-icon-setting"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="jobDetail">详情</el-dropdown-item>
                  <el-dropdown-item command="jobExport">下载</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
        <div id="round_two" v-show="round_twoData.length>0"></div>
        <section v-show="round_twoData.length==0" class="nodata1">
          <div class="img_box">
            <img class="img_box_img"
                src="@/assets/developping_images/developping.png">
          </div>
          <!-- 文字说明 -->
          <div class="desc_box">
            <span>暂无数据</span>
          </div>
        </section>
        <el-dialog :visible.sync="jobDialog">
          <div
            slot="title"
            style="display: flex;justify-content: center;align-items: center;color:#484848;"
          >岗位占比图详细数据</div>
          <div class="fr">
            <el-button size="small"
                      type="primary"
                      icon="el-icon-download"
                      @click="exportOut('jobExport')">导出
            </el-button>
          </div>
          <el-table :data="jobList" tooltip-effect="dark" style="width: 100%">
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column prop="status" label="岗位" align="center"></el-table-column>
            <el-table-column prop="number" label="数量" align="center"></el-table-column>
            <el-table-column prop="roportion" label="占比" align="center"></el-table-column>
          </el-table>
        </el-dialog>
      </div>
      <div class="round_item">
        <div class="header">
          <div class="header_left">
            <span class="header_left_title">offer状态占比</span>
            <span
              style="font-size:12px;color:#5c5e6b;margin:6px;"
            >{{dateVal[0]&&dateVal[0].slice(0,10)}} 至 {{dateVal[1]&&dateVal[1].slice(0,10)}}</span>
            <span>
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <i class="el-icon-setting"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="offerDetail">详情</el-dropdown-item>
                  <el-dropdown-item command="offerExport">下载</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
        <div id="round_three" v-show="round_threeData.length>0"></div>
        <section v-show="round_threeData.length==0" class="nodata1">
          <div class="img_box">
            <img class="img_box_img"
                src="@/assets/developping_images/developping.png">
          </div>
          <!-- 文字说明 -->
          <div class="desc_box">
            <span>暂无数据</span>
          </div>
        </section>
        <el-dialog :visible.sync="offerDialog">
          <div
            slot="title"
            style="display: flex;justify-content: center;align-items: center;color:#484848;"
          >offer状态占比图详细数据</div>
          <div class="fr">
            <el-button size="small"
                      type="primary"
                       icon="el-icon-download"
                      @click="exportOut('offerExport')">导出
            </el-button>
          </div>
          <el-table
            :data="offerStatsList"
            tooltip-effect="dark"
            v-loading="loading"
            style="width: 100%"
          >
            <el-table-column label="序号" type="index" align="center"></el-table-column>
            <el-table-column prop="status" label="offer状态" align="center"></el-table-column>
            <el-table-column prop="number" label="数量" align="center"></el-table-column>
            <el-table-column prop="roportion" label="占比" align="center"></el-table-column>
          </el-table>
        </el-dialog>
      </div>
    </div>
    <el-dialog :visible.sync="dialogFormVisible">
      <div
        slot="title"
        style="display: flex;justify-content: center;align-items: center;color:#484848;"
      >接收简历趋势图详细数据</div>
      <div class="fr">
        <el-button size="small" type="primary"  icon="el-icon-download" @click="exportOut('receiveExport')">导出</el-button>
      </div>
      <div style="height: 500px;overflow:auto;width: 100%;">
        <el-table :data="dataList" tooltip-effect="dark" v-loading="loading" style="width: 100%">
          <el-table-column label="序号" type="index" align="center"></el-table-column>
          <el-table-column prop="date" label="日期" align="center"></el-table-column>
          <el-table-column prop="dictName" label="渠道" show-overflow-tooltip align="center">
            <template v-slot="{row}">
              {{_returnDic(row.list,'dictName')}}
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量" show-overflow-tooltip align="center">
            <template v-slot="{row}">
              {{_returnDic(row.list,'num')}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chartsTime from './chartsTime.vue';
import {
  statisticsReceiveTrend,
  statisticsReceiveSourcesPiechart,
  statisticsReceiveJobPiechart,
  statisticsReceiveOfferPiechart
} from '@/api/recruitment_allocation.js';
export default {
  components: {
    chartsTime
  },
  data() {
    return {
      dateVal: [],
      dialogFormVisible: false,
      offerDialog: false,
      sourceDialog: false,
      jobDialog: false,
      loading: false,
      dataList: [],
      sourceList: [],
      seriesData: [],
      round_oneData: [],
      round_twoData: [],
      round_threeData: [],
      jobList: [],
      offerStatsList: [],
      fromtypeArr: [],
      yAxis: {
        name: '',
        nameTextStyle: {
          color: '#484848'
        },
        minInterval: 1,
        axisLabel: { color: '#484848' },
        splitArea: { show: false }, // 去除网格区域
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: '#D8D8D8',
            width: 1
          }
        }
      }
    };
  },
  mounted() {
    this.dateVal = this.$refs.chartsTime.dateVal;
    this.fromtypeArr = this.$store.state.app.dict.filter((item) => item.groupId == 'fromType');
    this.getReport();
    this.getRoundOne();
    this.getRoundTwo();
    this.getRoundThree();
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'receiveDetail':
          this.dialogFormVisible = true;
          break;
        case 'receiveExport':
          this.exportOut('receiveExport');
          break;
        case 'sourceDetail': // 渠道占比
          this.sourceDialog = true;
          break;
        case 'sourceExport':
          this.exportOut('sourceExport');
          break;
        case 'offerDetail':
          this.offerDialog = true;
          break;
        case 'jobDetail':
          this.jobDialog = true;
          break;
        case 'jobExport':
          this.exportOut('jobExport');
          break;
        default:
          this.exportOut('offerExport');
      }
    },
    exportOut(source) {
      const outParams = {
          startTime: this.dateVal[0],
          endTime: this.dateVal[1]
        };

        let router = '';

      switch (source) {
        case 'receiveExport': // 接收简历导出
          router = '/v1/recruit/statistics/receive/trend/export';
          break;
        case 'sourceExport': // 渠道占比导出
          router = '/v1/recruit/statistics/sources/piechart/export';
          break;
        case 'jobExport': // 岗位占比导出
          router = '/v1/recruit/statistics/job/piechart/export';
          break;
        case 'offerExport': // offer占比导出
          router = '/v1/recruit/statistics/offer/piechart/export';
          break;
        default:
          router = '';
      }
      const outStr = encodeURIComponent(JSON.stringify(outParams));

      const url = `${router}?params=${outStr}`;

      window.open(document.location.origin + url);
    },
    timeChange(data) {
      this.dateVal = data;
      this.getReport();
      this.getRoundOne();
      this.getRoundTwo();
      this.getRoundThree();
    },
    getReport() {
      const myChart = this.$echarts.init(document.getElementById('myChart_line'));

        const _series = {};

        const _seriesList = [];

        const _xlegend = [];

        const _selected = {};

        const _xlist = [];

        for (const key in this.fromtypeArr) {
          _xlegend.push(this.fromtypeArr[key].dictName);
          _series[this.fromtypeArr[key].dictName] = {
            name: this.fromtypeArr[key].dictName,
            type: 'line',
            data: []
          };
        }
      statisticsReceiveTrend({
        startTime: this.dateVal[0],
        endTime: this.dateVal[1]
      })
        .then((res) => {
          res = res.reverse();
          this.dataList = res;
          res.forEach((item) => {
            _xlist.push(item.date.slice(5));
            item.list.forEach((child) => {
              _series[child.dictName].data.push(child.num || 0);
            });
          });
          for (const keys in _series) {
            _selected[keys] = _series[keys].data.length > 0;
            _seriesList.push(_series[keys]);
          }
          this.seriesData = _seriesList;
          // this.table.total = res.totalNum
          this.loading = false;
        })
        .then(() => {
          myChart.clear();
          myChart.resize();
          myChart.setOption({
            tooltip: {
              trigger: 'axis',
              textStyle: {
                align: 'left'
              }
            },
            color: [
              '#55abf6',
              '#89e2d0',
              '#f8dc4a',
              '#b1ed93',
              '#93a8e9',
              '#ff92b9',
              '#c99dea'
            ],
            legend: {
              x: 'center',
              y: 'bottom',
              data: _xlegend,
              selected: _selected
            },
            grid: {
              //   left: '3%',
              //   right: '4%',
              //   bottom: '3%',
              //   containLabel: true
            },
            xAxis: {
              name: '日期',
              nameTextStyle: {
                color: '#484848'
              },
              type: 'category',
              axisLabel: { color: '#484848' },
              axisLine: {
                lineStyle: {
                  color: '#D8D8D8', // 颜色
                  width: 1 // 粗细
                }
              },
              boundaryGap: false,
              data: _xlist
            },
            yAxis: this.yAxis,
            series: _seriesList
          });
        });
    },
    // 渠道占比
    getRoundOne() {
      const round_one = this.$echarts.init(document.getElementById('round_one'));
        const xAxisData = [];
        const resData = [];

      statisticsReceiveSourcesPiechart({
        startTime: this.dateVal[0],
        endTime: this.dateVal[1]
      })
        .then((res) => {
          this.sourceList = res;
          res.forEach((item) => {
            xAxisData.push(item.sourcesName);
            resData.push({ value: item.number, name: item.sourcesName });
          });
          this.round_oneData = resData;
        })
        .then(() => {
          round_one.clear();
          round_one.resize();
          // 饼状
          round_one.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            // toolbox: {
            //   feature: {
            //     saveAsImage: { show: true }
            //   }
            // },
            legend: {
              orient: 'vertical',
              x: 'right',
              y: 'center',
              data: xAxisData
            },
            color: [
              '#55abf6',
              '#89e2d0',
              '#f8dc4a',
              '#b1ed93',
              '#93a8e9',
              '#ff92b9',
              '#c99dea'
            ],
            stillShowZeroSum: false,
            textStyle: {
              fontSize: 12,
              color: '#484848'
            },
            subtextStyle: {
              fontSize: 14,
              color: '#484848'
            },
            series: [
              {
                name: '渠道占比',
                minAngle: 1,
                type: 'pie',
                radius: '55%',
                center: ['35%', '55%'],
                data: resData,
                itemStyle: {
                  emphasis: {
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(128, 128, 128)'
                  }
                }
              }
            ]
          });
        });
    },
    // 岗位占比
    getRoundTwo() {
      const round_two = this.$echarts.init(document.getElementById('round_two'));
        const xAxisData = [];
        const resData = [];

      statisticsReceiveJobPiechart({
        startTime: this.dateVal[0],
        endTime: this.dateVal[1]
      })
        .then((res) => {
          this.jobList = res;
          res.forEach((item) => {
            xAxisData.push(item.status);
            resData.push({ value: item.number, name: item.status });
          });
          this.round_twoData = resData;
        })
        .then(() => {
          round_two.clear();
          round_two.resize();
          // 饼状
          round_two.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            // toolbox: {
            //   feature: {
            //     saveAsImage: { show: true }
            //   }
            // },
            legend: {
              orient: 'vertical',
              x: 'right',
              y: 'center',
              data: xAxisData
            },
            color: [
              '#55abf6',
              '#89e2d0',
              '#f8dc4a',
              '#b1ed93',
              '#93a8e9',
              '#ff92b9',
              '#c99dea'
            ],
            stillShowZeroSum: false,
            textStyle: {
              fontSize: 12,
              color: '#484848'
            },
            subtextStyle: {
              fontSize: 14,
              color: '#484848'
            },
            series: [
              {
                name: '会员等级占比',
                minAngle: 1,
                type: 'pie',
                radius: '55%',
                center: ['30%', '55%'],
                data: resData,
                itemStyle: {
                  emphasis: {
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(128, 128, 128)'
                  }
                }
              }
            ]
          });
        });
    },
    // offer占比
    getRoundThree() {
      const round_three = this.$echarts.init(
          document.getElementById('round_three')
        );
        const xAxisData = [];
        const resData = [];

      statisticsReceiveOfferPiechart({
        startTime: this.dateVal[0],
        endTime: this.dateVal[1]
      })
        .then((res) => {
          this.offerStatsList = res;
          res.forEach((item) => {
            xAxisData.push(item.status);
            resData.push({ value: item.number, name: item.status });
          });
          this.round_threeData = resData;
          // this.tableList = res.list
          // this.table.total = res.totalNum
        })
        .then(() => {
          round_three.clear();
          round_three.resize();
          // 饼状
          round_three.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            // toolbox: {
            //   feature: {
            //     saveAsImage: { show: true }
            //   }
            // },
            legend: {
              orient: 'vertical',
              x: 'right',
              y: 'center',
              data: xAxisData
            },
            color: [
              '#55abf6',
              '#89e2d0',
              '#f8dc4a',
              '#b1ed93',
              '#93a8e9',
              '#ff92b9',
              '#c99dea'
            ],
            stillShowZeroSum: false,
            textStyle: {
              fontSize: 12,
              color: '#484848'
            },
            subtextStyle: {
              fontSize: 14,
              color: '#484848'
            },
            series: [
              {
                name: 'offer状态占比',
                minAngle: 1,
                type: 'pie',
                radius: '55%',
                center: ['35%', '55%'],
                data: resData,
                itemStyle: {
                  emphasis: {
                    shadowBlur: 0,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(128, 128, 128)'
                  }
                }
              }
            ]
          });
        });
    },
    _returnDic(list, name) {
      if (list.length > 0) {
        const _list = [];

        for (const key in list) {
          _list.push(list[key][name]);
        }
        const _str = _list.join(',');

        return _str;
      }
        return '';

    }
  }
};
</script>
<style lang='scss' scoped>
.namethree .fr {
  margin: -10px 0 10px;
}
.box {
  padding-left: 12px;
  padding-bottom: 12px;
  // border: 1px solid #e5e5e5;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}
.round_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.round_item {
  width: 48.5%;

  // border: 1px solid #e5e5e5;
  padding: 12px;
}
#myChart_line,.nodata {
  height: 400px;
  width: 100%;
}
.nodata,
.nodata1 {
  .img_box {
    // width: 1200px;
    // min-height: 600px;
    // background-color: pink;
    text-align: center;
    height: 75%;
    .img_box_img {
      width: 30%;
      height: 100%
      // background-color: red;
    }
  }
  .desc_box {
    text-align: center;
    color: #333333;
    font-size: 14px;
    // padding: 10px 0;
  }
}
.nodata1 .img_box .img_box_img {
  width: 44%;
}
#round_one,
#round_two,
#round_three,
.nodata1 {
  height: 300px;
  width: 100%;
}
.header_left {
  .header_left_title{
    font-size:16px;
    color:#484848;
    font-weight: bold;
  }
  &:before {
    display: inline-block;
    width: 8px;
    height: 22px;
    background: #498df0;
    content: " ";
    border-radius: 5px;
    transform: translateY(-1px);
    vertical-align: middle;
    margin-right: 8px;
  }
}
</style>
