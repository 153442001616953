<!--
 * @Author: 卢均锐morye
 * @Date: 2020-03-05 09:16:27
 * @LastEditTime: 2020-04-13 10:05:58
 -->
<!--  -->
<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="招聘概览" name="0" lazy>
				<name-one></name-one>
			</el-tab-pane>
			<el-tab-pane label="招聘漏斗" name="1" lazy>
				<name-two></name-two>
			</el-tab-pane>
			<el-tab-pane label="招聘全数据" name="2" lazy>
				<name-three></name-three>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
import nameOne from './nameOne';
import nameTwo from './nameTwo';
import nameThree from './nameThree';
export default {
	components: {
		nameOne,
		nameTwo,
		nameThree
	},
	data() {
		return {
			activeName: '0'
		};
	},
	mounted() {},
	methods: {}
};
</script>
<style lang="scss" scoped>
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
