<!--  -->
<template>
  <div>
    <div class="box nametwo">
      <div class="header">
        <div class="header_left">
          <span class="header_left_title">招聘漏斗</span>
          <span style="font-size:12px;color:#5c5e6b;margin:6px;">{{dateVal[0]&&dateVal[0].slice(0,10)}} 至 {{dateVal[1]&&dateVal[1].slice(0,10)}}</span>
          <span>
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <i class="el-icon-setting"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">详情</el-dropdown-item>
                <el-dropdown-item command="1">下载</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
      </div>
      <charts-time @timeChange="timeChange" ref="chartsTime"></charts-time>
      <div id="myChart_two"></div>
    </div>
    <!-- 表格 -->
    <div class="box" style="margin-top:24px;padding:12px;">
      <div class="header_left" style="margin-bottom: 20px;">
        <span class="header_left_title">按岗位</span>
        <el-button size="small" style="float: right;margin-top: -4px;" type="primary" icon="el-icon-download" @click="exportOut('job')">导出</el-button>
      </div>
      <el-table :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="序号" type="index" align="center" fixed width="100px"></el-table-column>
        <el-table-column
          v-for="(col, i) in jobColumns"
          :prop="col.prop"
          :key="i"
          :label="col.label"
          align="center"
          width="200"
          show-overflow-tooltip>
      </el-table-column>
      </el-table>
      <!-- <div style="display: flex;justify-content: flex-end;">
        <el-pagination style="margin:20px 0"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="table.pageNo"
                       :page-sizes="[20, 50, 100, 200, 300, 400]"
                       :page-size="table.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="table.totalNum"></el-pagination>
      </div>-->
    </div>
    <el-dialog :visible.sync="dialogFormVisible">
      <div
        slot="title"
        style="display: flex;justify-content: center;align-items: center;color:#484848;"
      >招聘漏斗统计图详细数据</div>
      <div class="fr" style="margin: -10px 0 10px;">
        <el-button size="small" type="primary" @click="exportOut('table')" icon="el-icon-download">导出</el-button>
      </div>
      <div style="overflow:auto;height: 500px;width: 100%;">
        <el-table :data="tableList" tooltip-effect="dark" style="width: 100%">
          <el-table-column fixed prop="date" label="日期" align="center" width="150"></el-table-column>
          <el-table-column prop="newCount" label="接收简历" align="center" width="150"></el-table-column>
          <el-table-column prop="waitCount" label="面试邀约" align="center" width="150"></el-table-column>
          <el-table-column prop="interviewedCount" label="参加面试" align="center" width="150"></el-table-column>
          <el-table-column prop="offerCount" label="发送offer" align="center" width="150"></el-table-column>
          <el-table-column prop="toBeHiredCount" label="待入职" align="center" width="150"></el-table-column>
          <el-table-column prop="hasHiredCount" label="已入职" align="center" width="150"></el-table-column>
          <el-table-column prop="turnPositiveCount" label="已转正" align="center" width="150"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chartsTime from './chartsTime.vue';
import { statisticsHiringFunnel, statisticsJob } from '@/api/recruitment_allocation.js';
export default {
  components: {
    chartsTime
  },
  data() {
    return {
      dateVal: [],
      dialogFormVisible: false,
      loading: false,
      tableList: [],
      list: [],
      table: {
        pageNo: 1,
        pageSize: 20,
        totalNum: 0
      },
      yAxis: {
        name: '',
        nameTextStyle: {
          color: '#484848'
        },
        axisLabel: { color: '#484848' },
        splitArea: { show: false }, // 去除网格区域
        splitLine: { show: false },
        axisLine: {
          lineStyle: {
            color: '#D8D8D8',
            width: 1
          }
        }
      },
      jobColumns: [
        {prop: 'jobName', label: '岗位'},
        {prop: 'orgName', label: '需求部门'},
        {prop: 'number', label: '需求人数'},
        {prop: 'newCount', label: '接收简历'},
        {prop: 'waitCount', label: '面试邀约'},
        {prop: 'qualifiedRate', label: '简历合格率'},
        {prop: 'interviewedCount', label: '参加面试'},
        {prop: 'offerCount', label: '发送offer数量'},
        {prop: 'passRate', label: '面试通过率'},
        {prop: 'toHiredCount', label: '待入职'},
        {prop: 'hasHiredCount', label: '已入职'},
        {prop: 'hasHiredRate', label: '入职率'}
      ]
    };
  },
  mounted() {
    this.dateVal = this.$refs.chartsTime.dateVal;
    this.getReport();
    this._getJobList();
  },
  methods: {
    handleCommand(command) {
      if (command == '0') {
        this.dialogFormVisible = true;
      }
      if (command == '1') {
        this.exportOut('table');
      }
    },
    exportOut(source) {
      const outParams = {
          startTime: this.dateVal[0],
          endTime: this.dateVal[1]
        };

        let router = '';

      if (source == 'table') {
        router = '/v1/recruit/statistics/hiring/funnel/export';
      } else {
        router = '/v1/recruit/statistics/job/export';
      }
      const outStr = encodeURIComponent(JSON.stringify(outParams));

      const url = `${router}?params=${outStr}`;

      window.open(document.location.origin + url);
    },
    timeChange(data) {
      this.dateVal = data;
      this.getReport();
      this._getJobList();
    },

    getReport() {
      const myChart_two = this.$echarts.init(document.getElementById('myChart_two'));

let dataList = [];

      statisticsHiringFunnel({startTime: this.dateVal[0], endTime: this.dateVal[1]}).then((res) => {
          res = res.reverse();
          this.tableList = res;
          let _newCount = 0;

          let _waitCount = 0;

          let _interviewedCount = 0;

          let _offerCount = 0;
          // let _toBeHiredCount = 0;

          let _hasHiredCount = 0;

          let _turnPositiveCount = 0;

          res.forEach((item) => {
            _newCount += item.newCount;
            _waitCount += item.waitCount;
            _interviewedCount += item.interviewedCount;
            _offerCount += item.offerCount;
            // _toBeHiredCount = _toBeHiredCount +item.toBeHiredCount;
            _hasHiredCount += item.hasHiredCount;
            _turnPositiveCount += item.turnPositiveCount;
          });
          dataList = [
            { value: _newCount, name: '接收简历' },
            { value: _waitCount, name: '面试邀约' },
            { value: _interviewedCount, name: '参加面试' },
            { value: _offerCount, name: '发送offer' },
            // { value: _toBeHiredCount, name: "待入职" },
            { value: _hasHiredCount, name: '已入职' },
            { value: _turnPositiveCount, name: '已转正' }
          ];
        })
        .then(() => {
          myChart_two.clear();
          myChart_two.resize();
          myChart_two.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c}'
            },
            color: [
              '#498DF0',
              '#60C6BB',
              '#7DDCFF',
              '#7ED321',
              '#FBD233',
              '#FF7E00',
              '#FF7371'
            ],
            // toolbox: {
            //   feature: {
            //     dataView: { readOnly: false },
            //     restore: {},
            //     saveAsImage: {}
            //   }
            // },
            legend: {
              x: 'center',
              y: 'bottom',
              data: [
                '接收简历',
                '面试邀约',
                '参加面试',
                '发送offer',
                '待入职',
                '已入职',
                '已转正'
              ]
            },

            series: [
              {
                name: '漏斗图',
                type: 'funnel',
                left: '10%',
                top: 30,
                // x2: 80,
                bottom: 60,
                width: '80%',
                // height: {totalHeight} - y - y2,
                min: 0,
                max: 100,
                minSize: '0%',
                maxSize: '100%',
                sort: 'descending',
                gap: 2,
                label: {
                  show: true,
                  position: 'inside'
                },
                labelLine: {
                  length: 10,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                },
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 1
                },
                emphasis: {
                  label: {
                    fontSize: 20
                  }
                },
                data: dataList
              }
            ]
          });
        });
    },
    // 按岗位
    _getJobList() {
      statisticsJob({startTime: this.dateVal[0], endTime: this.dateVal[1]}).then((res) => {
        this.list = res;
      });
    },
    handleSizeChange(val) {
      this.table.pageSize = val;
      //   this.handleFilter()
    },
    handleCurrentChange(val) {
      this.table.pageNo = val;
      //   this.handleFilter()
    }
  }
};
</script>
<style lang='scss' scoped>
.box {
  padding-left: 12px;
  padding-bottom: 12px;
  // border: 1px solid #e5e5e5;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}
#myChart_two {
  height: 400px;
  width: 100%;
}
.header_left {
  .header_left_title{
    font-size:16px;
    color:#484848;
    font-weight: bold;
  }
  &:before {
    display: inline-block;
    width: 8px;
    height: 22px;
    background: #498df0;
    content: " ";
    border-radius: 5px;
    transform: translateY(-1px);
    vertical-align: middle;
    margin-right: 8px;
  }
}
</style>
