<template>
  <div class="chart_time" style="display:flex;margin:10px 0;">
    <el-date-picker
      size="small"
      :clearable="false"
      v-model="dateVal"
      type="daterange"
      align="right"
      :default-time="['00:00:00','23:59:59']"
      range-separator="至"
      :picker-options="pickerOptions"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd HH:mm:ss"
      @change="dateChange"
    ></el-date-picker>
  </div>
</template>
<script>
const invoice_end = new Date();
const invoice_start = new Date();

invoice_start.setTime(invoice_start.getTime() - 3600 * 1000 * 24 * 30);
const now = new Date(); // 当前日期
const nowDayOfWeek = now.getDay(); // 今天本周的第几天
const nowYear = now.getFullYear(); // 当前年
const nowMonth = now.getMonth(); // 月
const nowDay = now.getDate();

export default {
  props: {},
  data() {
    const getPreviousWeek = () => {// 获取上周
      // 获取上周起止
      // 起止日期数组
      const startStop = new Array();
      // 获取当前时间

      const currentDate = new Date();
      // 返回date是一周中的某一天

      const week = currentDate.getDay();
      // 一天的毫秒数

      const millisecond = 1000 * 60 * 60 * 24;
      // 减去的天数

      const minusDay = week != 0 ? week - 1 : 6;
      // 获得当前周的第一天

      const currentWeekDayOne = new Date(
        currentDate.getTime() - millisecond * minusDay
      );
      // 上周最后一天即本周开始的前一天

      const priorWeekLastDay = new Date(
        currentWeekDayOne.getTime() - millisecond
      );
      // 上周的第一天

      const priorWeekFirstDay = new Date(
        priorWeekLastDay.getTime() - millisecond * 6
      );
      // 添加至数组

      startStop.push(priorWeekFirstDay);
      startStop.push(priorWeekLastDay);
      return startStop;
    };
    const getPriorMonthFirstDay = (year, month) => {
        // 年份为0代表,是本年的第一月,所以不能减
        if (month == 0) {
            month = 11; // 月份为上年的最后月份
            year--; // 年份减1
            return new Date(year, month, 1);
        }
        // 否则,只减去月份
        month--;
        return new Date(year, month, 1);
    };
    const getMonthDays = (year, month) => {
        // 本月第一天 1-31
        const relativeDate = new Date(year, month, 1);
        // 获得当前月份0-11

        let relativeMonth = relativeDate.getMonth();
        // 获得当前年份4位年

        let relativeYear = relativeDate.getFullYear();

        // 当为12月的时候年份需要加1
        // 月份需要更新为0 也就是下一年的第一个月
        if (relativeMonth == 11) {
            relativeYear++;
            relativeMonth = 0;
        } else {
            // 否则只是月份增加,以便求的下一月的第一天
            relativeMonth++;
        }
        // 一天的毫秒数
        const millisecond = 1000 * 60 * 60 * 24;
        // 下月的第一天

        const nextMonthDayOne = new Date(relativeYear, relativeMonth, 1);
        // 返回得到上月的最后一天,也就是本月总天数

        return new Date(nextMonthDayOne.getTime() - millisecond).getDate();
    };
    const getPreviousMonth = () => {// 获取上月
      const startStop = new Array();
      // 获取当前时间

      const currentDate = new Date();
      // 获得当前月份0-11
      const currentMonth = currentDate.getMonth();
      // 获得当前年份4位年
      const currentYear = currentDate.getFullYear();
      // 获得上一个月的第一天
      const priorMonthFirstDay = getPriorMonthFirstDay(
        currentYear,
        currentMonth
      );
      // 获得上一月的最后一天
      const priorMonthLastDay = new Date(
        priorMonthFirstDay.getFullYear(),
        priorMonthFirstDay.getMonth(),
        getMonthDays(
          priorMonthFirstDay.getFullYear(),
          priorMonthFirstDay.getMonth()
        )
      );
      // 添加至数组

      startStop.push(priorMonthFirstDay);
      startStop.push(priorMonthLastDay);
      // 返回
      return startStop;
    };
    const getCurrentMonth = () => {// 获取本月
        // 起止日期数组
        const startStop = new Array();
        // 获取当前时间

        const currentDate = new Date();
        // 获得当前月份0-11

        let currentMonth = currentDate.getMonth();
        // 获得当前年份4位年

        let currentYear = currentDate.getFullYear();
        // 求出本月第一天

        const firstDay = new Date(currentYear, currentMonth, 1);
        // 当为12月的时候年份需要加1
        // 月份需要更新为0 也就是下一年的第一个月

        if (currentMonth == 11) {
            currentYear++;
            currentMonth = 0; // 就为
        } else {
            // 否则只是月份增加,以便求的下一月的第一天
            currentMonth++;
        }
        // 一天的毫秒数
        const millisecond = 1000 * 60 * 60 * 24;
        // 下月的第一天

        const nextMonthDayOne = new Date(currentYear, currentMonth, 1);
        // 求出上月的最后一天

        const lastDay = new Date(nextMonthDayOne.getTime() - millisecond);
        // 添加至数组中返回

        startStop.push(firstDay);
        startStop.push(lastDay);
        // 返回
        return startStop;
    };
    const getPreviousYear = () => { // 去年
        // 起止日期数组
        const startStop = new Array();
        // 获取当前时间

        const currentDate = new Date();
        // 获得当前年份4位年

        const currentYear = currentDate.getFullYear();
        // 本年第一天

        const currentYearFirstDate = new Date(currentYear, 0, 1);
        // 本年最后一天

        const currentYearLastDate = new Date(currentYear, 11, 31);
        // 添加至数组

        startStop.push(currentYearFirstDate);
        startStop.push(currentYearLastDate);
        // 返回
        return startStop;
    };
    const getCurrentYear = () => { // 本年
        // 起止日期数组
        const startStop = new Array();
        // 获取当前时间

        const currentDate = new Date();
        // 获得当前年份4位年

        let currentYear = currentDate.getFullYear();

        currentYear--;
        const priorYearFirstDay = new Date(currentYear, 0, 1);

        const priorYearLastDay = new Date(currentYear, 11, 1);
        // 添加至数组

        startStop.push(priorYearFirstDay);
        startStop.push(priorYearLastDay);
        return startStop;
    };

    return {
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
        // },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();

              end.setTime(end);
              start.setTime(start);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上周',
            onClick(picker) {
              const _arr = getPreviousWeek();
              const end = _arr[1];
              const start = _arr[0];

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '本周',
            onClick(picker) {
              const end = new Date(
                nowYear,
                nowMonth,
                nowDay + (6 - nowDayOfWeek + 1)
              );
              const start = new Date(
                nowYear,
                nowMonth,
                nowDay - nowDayOfWeek + 1
              );

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上月',
            onClick(picker) {
              const _arr = getPreviousMonth();
              const end = _arr[1];
              const start = _arr[0];

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '本月',
            onClick(picker) {
              const _arr = getCurrentMonth();
              const end = _arr[1];
              const start = _arr[0];

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '去年',
            onClick(picker) {
              const _arr = getCurrentYear();
              const end = _arr[1];
              const start = _arr[0];

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '今年',
            onClick(picker) {
              const _arr = getPreviousYear();
              const end = _arr[1];
              const start = _arr[0];

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '过去7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '过去30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      dateVal: [
        `${this.formatDate(invoice_start, 'yyyy-MM-dd')} 00:00:00`,
        `${this.formatDate(invoice_end, 'yyyy-MM-dd')} 23:59:59`
      ]
    };
  },
  created() {},
  mounted() {},
  methods: {
    dateChange() {
      const _list = [...this.dateVal];

      _list[0] = `${_list[0].slice(0, 10)} 00:00:00`;
      _list[1] = `${_list[1].slice(0, 10)} 23:59:59`;
      this.$emit('timeChange', _list);
    },
    formatDate(date, fmt) {
      if ((/(y+)/).test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (`${date.getFullYear()}`).substr(4 - RegExp.$1.length)
        );
      }
      const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };

      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = `${o[k]}`;

          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return String(fmt);
    },
    padLeftZero(str) {
      return (`00${str}`).substr(str.length);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
<style lang="scss">
.chart_time .el-date-editor .el-range-separator {
  width: 26px;
}
</style>
